/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";

import { ContentBlock, TextBlock } from "../../../components/Blocks";
import Contents from "../../../components/Contents";
import Pages from "../../../components/Pages";
import seoData from "../../../components/SEO/data";
import { Share } from "../../../widgets";
import Breadcrumbs from "../../../components/Layout/Breadcrumbs";
import { PureCarousel } from "../../../components/PureReactCarousel";
import { Image } from "../../../components/Media";
import { makeMediaData, sorter, makeNew, makeArticle, removePreloader, removeEmptyKeys } from "../../../helpers";
import { useThemeContext } from "../../../components/Layout/ThemeContext";
import { SERAFIM_PROJECTS_CONTENT, SERAFIM_PROJECTS_TEXT_PAGE_MAX_UPDATE } from "../../../queries/queries.graphql";
import * as TargetBanner from "../../../components/TargetBanner";

import "./serafimProjects.scss";

export const query = graphql`
	query SerafimPropjectsPageQuery($slug: String!) {
		hasura {
			...SerafimProjectsContent
			...Banners
		}
	}
`;

const MAKE_CONTENT_BY_TYPE_HASH = {
	news: makeNew,
	media_article: makeArticle,
};

export default function SerafimProjects({ data, pageContext }) {
	const { theme } = useThemeContext();

	const [serafimProjectsPage, setSerafimProjectsPage] = useState(get(data, "hasura", {}));

	const maxUpdated = new Date(get(data, "hasura.v_serafim_projects_updated_at[0].updated_at", new Date()));

	const currentPath = get(pageContext, "slug", "");

	const serafimProjectsOptions = {
		variables: {
			location: currentPath,
		},
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const [loadSerafimProjects, { called: serafimProjectsCalled, loading: serafimProjectsLoading, data: serafimProjectsData, error: serafimProjectsError }] = useLazyQuery(SERAFIM_PROJECTS_CONTENT, serafimProjectsOptions);
	delete serafimProjectsOptions.variables;
	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(SERAFIM_PROJECTS_TEXT_PAGE_MAX_UPDATE, serafimProjectsOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "v_serafim_projects_updated_at[0].updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadSerafimProjects();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load SerafimProjectsMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (serafimProjectsData && !serafimProjectsError) {
			setSerafimProjectsPage(serafimProjectsData || serafimProjectsPage);
			removePreloader(serafimProjectsCalled);
		} else if (serafimProjectsError) {
			console.error("Invalid load SerafimProjectsData", { serafimProjectsError });
			removePreloader();
		}
	}, [serafimProjectsLoading]);

	const projects = get(serafimProjectsPage, "serafim_projects", []);
	const banners = get(serafimProjectsPage, "banners", []);
	const page_title_full = get(serafimProjectsPage, "text_pages[0].page_title_full", "");
	const page_title_short = get(serafimProjectsPage, "text_pages[0].page_title_short", "");
	const content_blocks = get(serafimProjectsPage, "text_pages[0].content_blocks", []);

	const carouselItems = get(serafimProjectsPage, "v_serafim_projects_materials", [])
		.map(item => MAKE_CONTENT_BY_TYPE_HASH[item.type](item))
		.slice(0, 10);

	const videos = makeMediaData(get(
		serafimProjectsPage, "serafim_projects", [])
		.filter(item => get(item, "serafim_projects_media_videos.length", 0) > 0)
		.map(item => get(item, "serafim_projects_media_videos", []))
		.flat(2)
		.reduce((result, item) => {
			if (!result.some(res => res.id === item.media_video.id)) {
				result.push(item.media_video);
			}

			return result;
		}, [])
		.sort((a, b) =>
			sorter(+new Date(b.created_at), +new Date(a.created_at))), "videos",
	);

	// FIXME: Use query filters
	// @insaf
	const bannerBlock = banners
		.filter(banner => get(banner, "params.enabled", false) && get(banner, "params.locations", "").match(/serafim-project/))
		.map(banner => React.createElement(TargetBanner[get(banner, "params.type", "")] || TargetBanner.BannerImg, {
			...banner,
		}));

	return (
		<Pages
			entity={get(serafimProjectsPage, "text_pages[0]", seoData)}
			url={currentPath}
		>
			<ContentBlock key={"banner"} mount={!!bannerBlock.length}>
				<div className={"banner__main-block d-noscript-none"}>
					{bannerBlock}
				</div>
			</ContentBlock>
			<ContentBlock key={"Breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={page_title_short || page_title_full} />
				</div>
			</ContentBlock>
			<ContentBlock key={"about"}>
				<div className="container">
					<Contents items={content_blocks} />
				</div>
			</ContentBlock>
			<ContentBlock
				key={"projects"}
				mount={projects && !!projects.length}
				className={"serafim-projects__list"}
			>
				<div className="container">
					<TextBlock title={"Список проектов"}>
						<div className={"row d-flex justify-content-around mt-3"}>
							{
								projects.map(({ main_image, slug, page_title_full }, idx) => {
									return (
										<div
											key={idx}
											className={"col-xs-12 col-md-4 col-4 mb-3 mt-3 serafim-projects__item"}
										>
											<Link
												to={slug}
												className={
													"d-flex flex-column justify-content-center align-items-center link"
												}
												itemProp="url"
											>
												<Image
													src={get(main_image, "src", "")}
													style={{ height: 160 }}
													minHeight={140}
												/>
												<span
													style={removeEmptyKeys({
														fontFamily: theme.fontFamily,
														fontSize: theme.fontSize.text,
														lineHeight: theme.lineHeight.text,
														color: theme.color.text,
													})}
													className={"mt-3"}
												>
													{page_title_full}
												</span>
											</Link>
										</div>
									);
								})
							}
						</div>
					</TextBlock>
				</div>
			</ContentBlock>
			<ContentBlock key={"News"} mount={carouselItems && !!carouselItems.length}>
				<div className={"container"}>
					<TextBlock
						title={"Все материалы"}
						list={"Все материалы"}
						listLink={"/all-projects-materials"}
						allowBtn={true}
					>
						<PureCarousel
							type={"SlideNews"}
							items={carouselItems}
							mediaSize={["100%", 400]}
							slideRatio={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							slideRatioMobile={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							params={{
								slidesPerView: 4,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 768,
										slidesPerView: 2,
									},
									{
										width: 950,
										slidesPerView: 3,
									},
								],
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
			<ContentBlock key={"VideoGallery"} mount={videos && !!videos.length}>
				<div className={"container"}>
					<TextBlock
						title={"Видео"}
						list={"Все видео"}
						id={"Serafim-project-all-videos"}
						listLink={"/all-projects-videos"}
						allowBtn={videos && videos.length > 10}
					>
						<PureCarousel
							type={"SlideLinks"}
							items={videos.slice(0, 10)}
							slideRatio={{
								naturalSlideWidth: 2,
								naturalSlideHeight: 1,
							}}
							slideRatioMobile={{
								naturalSlideWidth: 1,
								naturalSlideHeight: 0.8,
							}}
							params={{
								slidesPerView: 2,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 768,
										slidesPerView: 2,
									},
								],
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
			<ContentBlock key={"share"}>
				<div className={"container"}>
					<Share url={currentPath} pageTitleShort={page_title_full} />
				</div>
			</ContentBlock>
		</Pages>
	);
}

SerafimProjects.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

SerafimProjects.defaultProps = {
	data: {},
	pageContext: {},
};
